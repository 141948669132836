<template>
  <b-card
    title="Customize ID"
  >
    <div class="d-flex justify-content-center">
      <div
        class="d-flex flex-column"
        style="width: 500px !important;"
      >
        <div id="id-card">
          <card1 :customization="customization" />
        </div>
        <div class="mt-1">
          <div class="mb-1">
            <strong>Card Information</strong>
          </div>
          <b-form-group>
            <b-form-checkbox-group
              v-model="customization.selected"
              :options="options"
            />
          </b-form-group>
        </div>
        <div>
          <div class="">
            <strong>Photo Border Radius</strong>
          </div>
          <input
            id="slider"
            v-model="customization.photo_border_radius"
            type="range"
            min="0"
            max="100"
            step="1"
          >
          <div class=" mt-1">
            <strong>Inner Card Radius</strong>
          </div>
          <input
            id="slider"
            v-model="customization.inner_card_radius"
            type="range"
            min="0"
            max="30"
            step="1"
          >
          <div class="mt-1">
            <strong>Theme</strong>
          </div>
          <div class="row mt-1">
            <div
              v-if="customization.gradient"
              class="col-md-4 mb-1"
            >
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="first-color"
                  v-model="customization.first_color"
                  type="color"
                >
                <span class="px-1"><label for="first-color">1st Color</label></span>
              </div>
            </div>
            <div
              v-if="customization.gradient"
              class="col-md-4 mb-1"
            >
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="bg-2-color"
                  v-model="customization.second_color"
                  type="color"
                >
                <span class="px-1"><label for="bg-2-color">2nd Color</label></span>
              </div>
            </div>
            <div
              v-if="!customization.gradient"
              class="col-md-4 mb-1"
            >
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="bg-color"
                  v-model="customization.bg_color"
                  type="color"
                >
                <span class="px-1"><label for="bg-color">Background</label></span>
              </div>
            </div>
            <div class="col-md-4 mb-1">
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="text-color"
                  v-model="customization.text_color"
                  type="color"
                >
                <span class="px-1"><label for="text-color">Text</label></span>
              </div>
            </div>
            <div class="col-md-4 mb-1">
              <b-form-checkbox
                v-model="customization.gradient"
                class="custom-control-primary"
              >
                Gradient
              </b-form-checkbox>
            </div>
            <div class="col-md-4 mb-1">
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="alt-bg-color"
                  v-model="customization.alt_bg_color"
                  type="color"
                >
                <span class="px-1"><label for="alt-bg-color">Alt Bg</label></span>
              </div>
            </div>
            <div class="col-md-4 mb-1">
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="issued-at-color"
                  v-model="customization.issued_date_text_color"
                  type="color"
                >
                <span class="px-1"><label for="issued-at-color">Issued At</label></span>
              </div>
            </div>
            <div class="col-md-4 mb-1">
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="barcode-color"
                  v-model="customization.barcode_color"
                  type="color"
                >
                <span class="px-1"><label for="barcode-color">Barcode</label></span>
              </div>
            </div>
            <div class="col-md-4 mb-1">
              <div
                class="d-flex align-items-center"
              >
                <input
                  id="logo-color"
                  v-model="customization.logo_bg"
                  type="color"
                >
                <span class="px-1"><label for="logo-color">Logo Bg</label></span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <am-button
            class="float-right ml-1"
            label="Save"
            :disabled="customizationLoading"
            :loading="customizationLoading"
            @click="saveCustomization()"
          />
          <am-button
            class="float-right"
            variant="outline-primary"
            label="Reset"
            icon="RefreshCwIcon"
            :loading="resetLoading"
            :disabled="resetLoading"
            @click="resetToDefault()"
          />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import showToast from '@/farmbusiness/mixins/showToast'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import Card1 from './Card1.vue'

export default {
  components: {
    AmButton,
    Card1,
    BCard,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  mixins: [showToast],
  data() {
    return {
      customizationLoading: false,
      resetLoading: false,
      forceCustomizationUpdate: false,
      customization: {
        selected: [],
        gradient: true,
        first_color: null,
        second_color: null,
        bg_color: null,
        alt_bg_color: null,
        text_color: null,
        issued_date_text_color: null,
        barcode_color: null,
        photo_border_radius: 30,
        inner_card_radius: 30,
        logo_x: 30,
        logo_y: 50,
        photo_x: 100,
        photo_y: 105,
        member_x: 30,
        member_y: 50,
        company_x: 340,
        company_y: 105,
        title_x: 100,
        title_y: 50,
        barcode_x: 100,
        barcode_y: 200,
        issued_x: 100,
        issued_y: 270,
        logo_bg: null,
      },
      options: [
        {
          text: 'Logo',
          value: 'logo',
        },
        {
          text: 'Member ID',
          value: 'id',
        },
        {
          text: ' Gender',
          value: 'gender',
        },
        {
          text: 'Farmer Group',
          value: 'org',
        },
        {
          text: 'Dob',
          value: 'dob',
        },
      ],
    }
  },
  computed: {
    idCardCustomization() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.forceCustomizationUpdate = !this.forceCustomizationUpdate
      return this.$store.getters['memberId/memberIdCustomization']
    },
    selectedOptions() {
      return this.customization.selected
    },
  },
  watch: {
    selectedOptions(selected) {
      // we do not want a lot of information on id card
      // make sure only 4 options are selected excluding logo
      // eslint-disable-next-line no-param-reassign
      if (selected.length > 4) {
        const first = selected[0]
        this.customization.selected = this.customization.selected.filter(s => s !== first)
      }
    },
    idCardCustomization(cs) {
      const { customization } = cs
      console.log(JSON.stringify(cs))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.customization = { ...customization }
    },
  },
  mounted() {
    this.forceCustomizationUpdate = !this.forceCustomizationUpdate
    this.getCustomizations()
  },
  methods: {
    resetToDefault() {
      this.saveCustomization(true)
    },
    saveCustomization(reset = false) {
      if (reset) {
        this.resetLoading = true
      } else {
        this.customizationLoading = true
      }
      const customization = {
        id: this.idCardCustomization.id,
        type: 'id-card',
        customization: this.customization,
        reset,
      }
      console.log(customization)
      this.$store.dispatch('memberId/updateCustomization', customization).then(res => {
        this.showSuccess('Saved customization successfully')
      }).catch(err => {
        this.showError('Failed to save customization')
      }).finally(() => {
        this.customizationLoading = false
        this.resetLoading = false
      })
    },
    getCustomizations() {
      this.$store.dispatch('memberId/getCustomization', 'id-card')
    },
  },
}
</script>
<style lang="scss" scoped>
/* Style the slider track */
input[type="range"] {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 100%;
  height: 10px; /* Adjust the height of the track */
  background: white; /* Change the track color to red */
  outline: none; /* Remove the outline */
}

/* Style the filled part of the slider track in WebKit */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px; /* Adjust the height of the filled track */
  background: green; /* Change the filled track color to green */
}

/* Style the filled part of the slider track in Firefox */
input[type="range"]::-moz-range-progress {
  background: green; /* Change the filled track color to green */
}
/* Style the slider thumb (handle) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 20px; /* Adjust the width of the thumb */
  height: 20px; /* Adjust the height of the thumb */
  background: #fff; /* Change the thumb color to white */
  border: 2px solid green; /* Add a border around the thumb */
  border-radius: 50%; /* Make the thumb round */
  cursor: pointer; /* Change the cursor to a pointer */
}

/* Style the slider thumb for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid green;
  border-radius: 50%;
  cursor: pointer;
}
</style>
